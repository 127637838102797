import React from "react";
import "../../CSS/main.css";
import uiPic1 from "../../Images/NewImages/UX UI Design/UI Portfolio Image 1.webp";
import uiPic2 from "../../Images/NewImages/UX UI Design/UI Portfolio Image2.webp";
import uiPic3 from "../../Images/NewImages/UX UI Design/UI Portfolio Image3.webp";
import uiPic4 from "../../Images/NewImages/UX UI Design/UI Portfolio Image 4.webp";
import uiPic5 from "../../Images/NewImages/UX UI Design/UI Portfolio Image 5.webp";
import uiPic6 from "../../Images/UIPic/uiPic-6.jpg";

const UiUx = () => {
  return (
    <div className="UI-UX-body container">
      <h5 className="text-center ui-ux-title-text">
        Customize the look of your site, or <br /> build a new one from Figma.
      </h5>

      <div className="quote-button">
        <button className="free-quote-button" type="submit">
          Get a free quote
        </button>
      </div>

      <div className="row ms-1 gap-0 mt-3">
        <div className="col-md-4">
          <a href="https://www.behance.net/gallery/146372217/Agency-Website-Design">
            <img className="ui-Picture mt-4" src={uiPic1} alt="ui pic" />
          </a>
        </div>
        <div className="col-md-4">
          <a href="https://www.behance.net/gallery/157058071/Football-Club-UIUX-Design">
            <img className="ui-Picture mt-4" src={uiPic2} alt="ui pic" />
          </a>
        </div>
        <div className="col-md-4">
          <a href="https://www.behance.net/gallery/150437973/Banking-Landing-Page">
            <img className="ui-Picture mt-4" src={uiPic3} alt="ui pic" />
          </a>
        </div>
        <div className="col-md-4">
          <a href="{}">
            <img className="ui-Picture mt-4" src={uiPic4} alt="ui pic" />
          </a>
        </div>
        <div className="col-md-4">
          <a href="https://www.behance.net/gallery/127493653/Dhaka-Break-Sport-Website-Design">
            <img className="ui-Picture mt-4" src={uiPic5} alt="ui pic" />
          </a>
        </div>
        <div className="col-md-4">
          <a href="https://dribbble.com/mithunmrinmoy">
            <img className="ui-Picture mt-4" src={uiPic6} alt="ui pic" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default UiUx;
