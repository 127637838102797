import React from "react";
import "../CSS/main.css";
import { Card } from "react-bootstrap";
import Slider from "react-slick";
import crewPic1 from "../Images/NewImages/Team/Mrinmoy Krishna Roy - UX UI Designer_4_11zon.webp";
import nur from "../Images/NewImages/Team/Asrafuzzaman Nur_1_11zon.webp";
import ahmed from "../Images/NewImages/Team/Shahariar Ahmad - Full-stack Developer_7_11zon.webp";
import bayzid from "../Images/NewImages/Team/Bayzid Islam - UX UI Designer_2_11zon.webp";
import sakib from "../Images/NewImages/Team/Sazzid Sakib - Jr Web Developer_6_11zon.webp";
import sojib from "../Images/NewImages/Team/Sojib Mahmud - Marketing Strategist_8_11zon.webp";
import timir from "../Images/NewImages/Team/Timir Deb Nath - Motion Artist_10_11zon.webp";
import tilok from "../Images/NewImages/Team/Tilok Chandra Paul  - Visualizer_9_11zon.webp";
import rimon from "../Images/NewImages/Team/Muhammad Rimon - UX UI Designer_5_11zon.webp";

const OurMembers = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="our-members-body container" id="team">
      <p className="text-center service-top-paragraph">our member</p>
      <h1 className="mt-1">Our Amazing Crew</h1>

      <div>
        <Slider {...settings}>
          <div className="col-md-3 mt-4">
            <Card className="crew-card">
              <Card.Img className="crew-image mx-auto" variant="top" src={crewPic1} />
              <Card.Body className="crew-info">
                <h5 className="text-center mt-1 mb-3">Mrinmoy Krishna Roy</h5>
                <p className="text-center">Senior UX UI Designer</p>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-3 mt-4">
            <Card className="crew-card">
              <Card.Img className="crew-image mx-auto" variant="top" src={bayzid} />
              <Card.Body className="crew-info">
                <h5 className="text-center mt-1 mb-3">Bayzid Islam</h5>
                <p className="text-center">UX UI Designer</p>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-3 mt-4">
            <Card className="crew-card">
              <Card.Img className="crew-image mx-auto" variant="top" src={ahmed} />
              <Card.Body className="crew-info">
                <h5 className="text-center mt-1 mb-3">Shahariar Ahmad</h5>
                <p className="text-center">Full-stack Developer</p>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-3 mt-4">
            <Card className="crew-card">
              <Card.Img className="crew-image mx-auto" variant="top" src={rimon} />
              <Card.Body className="crew-info">
                <h5 className="text-center mt-1 mb-3">Muhammad Rimon</h5>
                <p className="text-center">UX UI Designer</p>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-3 mt-4">
            <Card className="crew-card">
              <Card.Img className="crew-image mx-auto" variant="top" src={nur} />
              <Card.Body className="crew-info">
                <h5 className="text-center mt-1 mb-3">Asrafuzzaman Nur</h5>
                <p className="text-center">Software Developer</p>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-3 mt-4">
            <Card className="crew-card">
              <Card.Img className="crew-image mx-auto" variant="top" src={sakib} />
              <Card.Body className="crew-info">
                <h5 className="text-center mt-1 mb-3">Sazzid Sakib</h5>
                <p className="text-center">Jr Web Developer</p>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-3 mt-4">
            <Card className="crew-card">
              <Card.Img className="crew-image mx-auto" variant="top" src={sojib} />
              <Card.Body className="crew-info">
                <h5 className="text-center mt-1 mb-3">Sojib Mahmud</h5>
                <p className="text-center">Marketing Strategist</p>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-3 mt-4">
            <Card className="crew-card">
              <Card.Img className="crew-image mx-auto" variant="top" src={timir} />
              <Card.Body className="crew-info">
                <h5 className="text-center mt-1 mb-3">Timir Deb Nath</h5>
                <p className="text-center">Motion Artist</p>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-3 mt-4">
            <Card className="crew-card">
              <Card.Img className="crew-image mx-auto" variant="top" src={tilok} />
              <Card.Body className="crew-info">
                <h5 className="text-center mt-1 mb-3">Tilok Chandra Paul</h5>
                <p className="text-center">Visualizer</p>
              </Card.Body>
            </Card>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default OurMembers;
