import React, { useRef } from "react";
import { SiMinutemailer } from "react-icons/si";
import addressCard from "../Images/address-card.png";
import phoneCard from "../Images/phone-card.png";
import mailCard from "../Images/mail-card.png";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LetsTalk = () => {
  const form = useRef();
  const notify = () => toast("Message is sent");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm("service_rtot9b5", "template_sqogazb", form.current, "U5clgeWO0TzL0u_aP").then(
      (result) => {
        notify();
      },
      (error) => {
        alert(error.message);
      }
    );
    e.target.reset();
  };

  return (
    <div className="lets-talk-body">
      <div className="container lets-talk-box">
        <h1 className="mb-4">Let’s Talk</h1>

        <div>
          <form ref={form} onSubmit={sendEmail}>
            <div className="mb-3">
              <label for="exampleInputEmail1" className="form-label mb-0">
                Your Name and Company
              </label>
              <input
                name="name"
                type="text"
                className="form-control-lets-talk"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>
            <div className="mb-3 mt-4">
              <label for="exampleInputEmail1" className="form-label mb-0">
                Your email
              </label>
              <input
                name="email"
                type="email"
                className="form-control-lets-talk"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>
            <div className="mb-3 mt-4">
              <label for="exampleInputEmail1" className="form-label mb-0">
                Project Brief
              </label>
              <input
                name="project-brief"
                type="text"
                className="form-control-lets-talk"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                required
              />
            </div>
            <div className="mb-3 mt-4">
              <label for="exampleInputEmail1" className="form-label mb-0">
                A little about your company and your position
              </label>
              <input
                name="company-position"
                type="text"
                className="form-control-lets-talk"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                required
              />
            </div>
            <div className="mb-3 mt-4">
              <label for="exampleInputEmail1" className="form-label mb-0">
                Website URL
              </label>
              <input
                name="url"
                type="text"
                className="form-control-lets-talk"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                required
              />
            </div>
            <button type="submit" className="btn px-4 py-3 btn-primary mt-4">
              Send a message <SiMinutemailer className="fs-5 ms-2" />
            </button>
            <ToastContainer />

            <p className="mt-4">
              For your other inquiries reach us out here! <strong>info@earthycolor.agency</strong>
            </p>
          </form>

          <div className="row contact-cards px-0">
            <div className="col-md p-3">
              <img src={addressCard} alt="" />
            </div>
            <div className="col-md p-3">
              <img src={phoneCard} alt="" />
            </div>
            <div className="col-md p-3">
              <img src={mailCard} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LetsTalk;
