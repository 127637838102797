import React from "react";
import {useNavigate} from 'react-router-dom';
import { MdArrowForwardIos } from "react-icons/md";
import { SiMinutemailer } from "react-icons/si";
import blog1 from "../Images/BlogsImage/blog-1.png";
import blog2 from "../Images/BlogsImage/blog-2.png";
import blog3 from "../Images/BlogsImage/blog-3.png";
import sendMessageCard from "../Images/send-message-card.png";

const Blogs = () => {
  const navigate = useNavigate();

  const talkPage = () =>{
    navigate('/letsTalk');
  }

  return (
    <div className="blogs-body" id="blog">
      <p className="text-center service-top-paragraph">our news</p>
      <h1>Updates from our Blog</h1>

      <div className="container blog-box">
        <div className="row gap-4">
          <div className="col-md bc-1 text-center">
            <p className="text-center fs-5 fw-bold text-white">The future hits crypto.</p>
            <a className="learn-more-link" href="{}">
              Learn more <MdArrowForwardIos />
            </a>

            <div>
              <img className="mt-5" src={blog1} alt="" />
            </div>
          </div>
          <div className="col-md bc-2 text-center">
            <p className="text-center fs-5 fw-bold text-white">The future hits crypto.</p>
            <a className="learn-more-link" href="{}">
              Learn more <MdArrowForwardIos />
            </a>

            <div>
              <img className="mt-5" src={blog2} alt="" />
            </div>
          </div>
          <div className="col-md bc-3 text-center">
            <p className="text-center fs-5 fw-bold text-white">The future hits crypto.</p>
            <a className="learn-more-link" href="{}">
              Learn more <MdArrowForwardIos />
            </a>

            <div>
              <img src={blog3} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="container lets-chat-body">
        <div className="message-title-and-button">
          <h1 className="text-start">Got a brief? Let's chat!</h1>
          <button onClick={talkPage} type="">
            Send a message <SiMinutemailer className="fs-5" />
          </button>
        </div>
        <img src={sendMessageCard} alt="" />
      </div>
    </div>
  );
};

export default Blogs;
