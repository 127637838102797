import React from "react";
import { Card } from "react-bootstrap";
import uiux from "../Images/Services/uiuix.png";
import development from "../Images/Services/development.png";
import branding from "../Images/Services/brandingdesign.png";
import growthmarketing from "../Images/Services/growmarketing.png";
import animation from "../Images/Services/animation.png";
import business from "../Images/Services/businessstratagy.png";

const Services = () => {
  return (
    <div className="services-body" id="services">
      <p className="text-center service-top-paragraph">our services</p>
      <h1>Services we Offer</h1>
      <p className="text-center sub-paragraph">
        We’ve created branding and websites for over a hundred digital <br /> companies around the world. We understand
        your marketing & goals
      </p>

      <div className="container">
        <div className="row mt-4">
          <div className="col-md-4">
            <Card className="service-card mt-3" style={{ width: "auto" }}>
              <Card.Body>
                <div className="mt-3 ms-2">
                  <img className="services-image" src={uiux} alt="" />
                  <h5 className="service-card-title">UX UI Design</h5>
                  <p className="mt-3">
                    Webdesign (User Interface and User Experience) is our historical expertise. We’re constantly seeking
                    to strike the right balance between efficiency and invention.
                  </p>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
            <Card className="service-card mt-3" style={{ width: "auto" }}>
              <Card.Body>
                <div className="mt-3 ms-2">
                  <img className="services-image" src={development} alt="" />
                  <h5 className="service-card-title">Development</h5>
                  <p className="mt-3">
                    We’re artists with a brush—and HTML. Putting finger to key, we use a unique blend of UX, UI and
                    maybe a little red ochre to create a lead-generating masterpiece that tells the world who you are.
                  </p>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
            <Card className="service-card mt-3" style={{ width: "auto" }}>
              <Card.Body>
                <div className="mt-3 ms-2">
                  <img className="services-image" src={branding} alt="" />
                  <h5 className="service-card-title">Branding Design</h5>
                  <p className="mt-3">
                    To us, branding has to inspire action. That’s why we do the deep research, develop your unique brand
                    story, then bring it to life in a way that cannot be ignored.
                  </p>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-4">
            <Card className="service-card mt-3" style={{ width: "auto" }}>
              <Card.Body>
                <div className="mt-3 ms-2">
                  <img className="services-image" src={growthmarketing} alt="" />
                  <h5 className="service-card-title">Growth Marketing</h5>
                  <p className="mt-3">
                    Today's social strategies? Already outdated. Let's talk about what tomorrow can bring.
                  </p>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
            <Card className="service-card mt-3" style={{ width: "auto" }}>
              <Card.Body>
                <div className="mt-3 ms-2">
                  <img className="services-image" src={animation} alt="" />
                  <h5 className="service-card-title">Animation</h5>
                  <p className="mt-3">
                    Our animation solutions allow us to generate state-of-the-art visual imagery for feature-length
                    animation short-form media, brand and image marketing etc.
                  </p>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
            <Card className="service-card mt-3" style={{ width: "auto" }}>
              <Card.Body>
                <div className="mt-3 ms-2">
                  <img className="services-image" src={business} alt="" />
                  <h5 className="service-card-title">Business Stratagy</h5>
                  <p className="mt-3">
                    Great strategy is simple and focused. Because we believe focus goes further. It’s about reaching the
                    maximum number of qualified leads with the greatest efficiency.
                  </p>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
