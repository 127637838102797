import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import "../src/Components/CSS/main.css";
import "../src/Components/CSS/responsive.css";
import Footer from "./Components/Footer/Footer";
import Home from "./Components/Home/Home";
import ScrollRestore from "./Components/Hooks/ScrollRestore";
import LetsTalk from "./Components/LetsTalk/LetsTalk";
import Navbar from "./Components/Navbar/Navbar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <div>
      <Navbar></Navbar>
      <ScrollRestore></ScrollRestore>
      <Routes>
        <Route path="/" element={<Home></Home>} />
        <Route path="home" element={<Home></Home>} />
        <Route path="letsTalk" element={<LetsTalk></LetsTalk>} />
      </Routes>
      <ScrollToTop smooth className="top-button" />
      <Footer></Footer>
    </div>
  );
}

export default App;
