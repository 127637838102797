import React from "react";
import Hero from "../HeroSection/Hero";
import Services from "../Services/Services";
import RecentWorks from "./../RecentWorks/RecentWorks";
import WeProvide from "./../WeProvide/WeProvide";
import OurMembers from "./../OurMembers/OurMembers";
import OurClients from "./../OurClients/OurClients";
import HappyCustomer from "./../HappyCustomer/HappyCustomer";
import FAQ from "./../FAQ/FAQ";
import Blogs from "./../Blogs/Blogs";
import Bulletin from "../Bulletin/Bulletin";

const Home = () => {
  return (
    <div>
      <Hero></Hero>
      <Bulletin></Bulletin>
      <Services></Services>
      <RecentWorks></RecentWorks>
      <WeProvide></WeProvide>
      <OurMembers></OurMembers>
      <OurClients></OurClients>
      <HappyCustomer></HappyCustomer>
      <FAQ></FAQ>
      <Blogs></Blogs>
    </div>
  );
};

export default Home;
