import React from "react";
import footerLogo from "../Images/Logo/footer-logo.png";
import { SiDribbble } from "react-icons/si";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { BsBehance, BsInstagram, BsYoutube } from "react-icons/bs";

const Footer = () => {
  return (
    <div className="footer-body" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-md">
            <img src={footerLogo} alt="" />

            <p className="text-white mt-4 w-75">
              Get a fully stacked design team that will give you accessible design, exhaustive research, and an
              outstanding experience
            </p>
          </div>
          <div className="col-md text-white">
            <p className="fs-5 fw-bold">Say Hello</p>
            <p>Email: admin@example.com</p>
            <p>Phone: 01303 013 164</p>
          </div>
          <div className="col-md text-white">
            <p className="fs-5 fw-bold">Follow us</p>
            <div className="social-links">
              <a target="_blank" href="https://dribbble.com/mithunmrinmoy">
                {" "}
                <SiDribbble />{" "}
              </a>
              <a target="_blank" className="ms-3" href="https://www.facebook.com/earthycolor.agency">
                {" "}
                <FaFacebookF />{" "}
              </a>
              <a
                target="_blank"
                className="ms-3"
                href="https://www.linkedin.com/company/earthycolor-agency/?viewAsMember=true"
              >
                {" "}
                <FaLinkedinIn />{" "}
              </a>
              <a target="_blank" className="ms-3" href="https://www.behance.net/earthycolor_agency">
                {" "}
                <BsBehance />{" "}
              </a>
              <a target="_blank" className="ms-3" href="https://www.youtube.com/@earthycolor.agency">
                {" "}
                <BsYoutube />{" "}
              </a>
              <a target="_blank" className="ms-3" href="https://www.instagram.com/earthycolor.agency/">
                {" "}
                <BsInstagram />{" "}
              </a>
            </div>
          </div>
        </div>

        <div className="white-line"></div>
        <div className="text-center pt-3 pb-3">
          <small className="text-white ">Ⓒ Copyright by EarthyColor | All rights reserved | 2022</small>
        </div>
      </div>
    </div>
  );
};

export default Footer;
