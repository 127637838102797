import React from "react";
import bi1 from "../../Images/NewImages/Branding/Branding01_1_11zon.webp";
import bi2 from "../../Images/NewImages/Branding/Branding02_2_11zon.webp";
import bi3 from "../../Images/NewImages/Branding/Branding03_3_11zon.webp";
import bi4 from "../../Images/NewImages/Branding/Branding04_4_11zon.webp";
import bi5 from "../../Images/NewImages/Branding/Branding05_5_11zon.webp";
import bi6 from "../../Images/NewImages/Branding/Branding06_6_11zon.webp";

const BrandingDesign = () => {
  return (
    <div className="UI-UX-body container">
      <h5 className="text-center ui-ux-title-text">We make your brand colorfull, <br/> trendy & usefull.</h5>

      <div className="quote-button">
        <button className="free-quote-button" type="submit">
          Get a free quote
        </button>
      </div>

      <div className="row ms-1 gap-0 mt-3">
        <div className="col-md-4">
          <img className="ui-Picture mt-4" src={bi1} alt="branding pic" />
        </div>
        <div className="col-md-4">
          <img className="ui-Picture mt-4" src={bi2} alt="branding pic" />
        </div>
        <div className="col-md-4">
          <img className="ui-Picture mt-4" src={bi3} alt="branding pic" />
        </div>
        <div className="col-md-4">
          <img className="ui-Picture mt-4" src={bi4} alt="branding pic" />
        </div>
        <div className="col-md-4">
          <img className="ui-Picture mt-4" src={bi5} alt="branding pic" />
        </div>
        <div className="col-md-4">
          <img className="ui-Picture mt-4" src={bi6} alt="branding pic" />
        </div>
      </div>
    </div>
  );
};

export default BrandingDesign;
