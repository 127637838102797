import React from "react";
import client1 from "../Images/Clients/client1.png"
import client2 from "../Images/Clients/client2.png"
import client3 from "../Images/Clients/client3.png"
import client4 from "../Images/Clients/client4.png"

const OurClients = () => {
  return (
    <div className="container our-clients-body">
      <h3>Our Clients</h3>
      <div className="client-image-box">
        <div className="">
            <img className="client-image1" src={client1} alt=""/>
        </div>
        <div className="">
            <img className="client-image2" src={client2} alt=""/>
        </div>
        <div className="">
            <img className="client-image3" src={client3} alt=""/>
        </div>
        <div className="">
            <img className="client-image4" src={client4} alt=""/>
        </div>
      </div>
    </div>
  );
};

export default OurClients;
