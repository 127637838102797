import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import "../CSS/main.css";
import Animation from "./Tabs/Animation";
import BrandingDesign from "./Tabs/BrandingDesign";
import Ddesign from "./Tabs/Ddesign";
// import Animation from "./Tabs/Animation";
// import BrandingDesign from "./Tabs/BrandingDesign";
// import Ddesign from "./Tabs/Ddesign";
import UiUx from "./Tabs/UiUx";

const RecentWorks = () => {
  return (
    <div className="recent-works-body" id="portfolio">
      <p className="text-center service-top-paragraph">our portfolio</p>
      <h1>Some of our Recent Works</h1>

      <div className="tabs-body container mt-5">
        <Tabs defaultActiveKey="ui" className="tabs gap-3 mb-0 w-50 mx-auto">
          <Tab eventKey="ui" title="UI UX Design">
            <UiUx></UiUx>
          </Tab>
          <Tab eventKey="branding" title="Branding Design">
            <BrandingDesign/>
          </Tab>
          <Tab eventKey="animation" title="Animation">
            <Animation></Animation>
          </Tab>
          <Tab eventKey="design" title="More">
            <Ddesign></Ddesign>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default RecentWorks;
