import React from "react";
import { Card } from "react-bootstrap";
import moneyLogo from "../Images/money-logo.png";
import supportCardEmail from "../Images/support-card-mail.png";
import { useNavigate } from "react-router-dom";

const WeProvide = () => {
  const navigate = useNavigate();

  const talkPage = () => {
    navigate("/letsTalk");
  };

  return (
    <div className="we-provide-body">
      <div className="container">
        <div className="row">
          <div className="col-md-5 provide-box">
            <h2>
              We provide <br /> you <span>all-in-one</span> <br /> remote solution
            </h2>
            <p className="w-75">
              We rigorously research, design, develop and iterate to provide the best sustainable solution for your
              business. We help you to make your business colorful
            </p>
            <div className="mt-5">
              <button onClick={talkPage} className="free-quote-button" type="submit">
                Start a project
              </button>
            </div>
          </div>
          <div className="col-md-7">
            <div className="d-lg-flex d-sm-block">
              <div>
                <Card className="pricing-card ps-1 pt-1">
                  <Card.Body>
                    <img className="mt-3" src={moneyLogo} alt="money logo" />
                    <h5>Affordable Pricing</h5>
                    <p>
                      We will make your work <br /> beautifully in a budget <br /> friendly way. <br /> We want to help{" "}
                      <br /> startup company
                    </p>
                  </Card.Body>
                </Card>
              </div>
              <div>
                <Card className="talent-card ms-4">
                  <Card.Body>
                    <h5>Top Talent</h5>
                    <p className="mt-4">
                      Thoroughly vetted team of talent <br /> that is incentivized to deliver.
                    </p>
                  </Card.Body>
                </Card>
                <Card className="support-card ms-4 mt-4">
                  <Card.Body>
                    <img src={supportCardEmail} alt="" />
                    <h5 className="mt-4">Custom Support</h5>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex d-md-flex d-sm-block provide-bottom-number">
          <div className="d-flex number-start">
            <h2>02+</h2>
            <p className="ms-3">
              YEARS <br /> EXPERIENCE
            </p>
          </div>
          <div className="d-flex numbers-bottom">
            <h2>20+</h2>
            <p className="ms-3">
              HAPPY <br /> CLIENTS
            </p>
          </div>
          <div className="d-flex numbers-bottom">
            <h2>1+</h2>
            <p className="ms-3">
              MILLION <br /> RAISED
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeProvide;
