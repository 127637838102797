import React from "react";
import { FcRating } from 'react-icons/fc';

const Bulletin = () => {
  return (
    <div class="ticker-wrapper-h">
      <ul class="news-ticker-h">
        <li>
          <a href="{}">User Journey <FcRating className="ms-3"/></a>
        </li>
        <li>
          <a href="{}">UI UX Design <FcRating className="ms-3"/></a>
        </li>
        <li>
          <a href="{}">Branding <FcRating className="ms-3"/></a>
        </li>
        <li>
          <a href="{}">Web Development <FcRating className="ms-3"/></a>
        </li>
        <li>
          <a href="{}">Marketing <FcRating className="ms-3"/></a>
        </li>
        <li>
          <a href="{}">MERN Full Stack Development <FcRating className="ms-3"/></a>
        </li>
        <li>
          <a href="{}">Animation <FcRating className="ms-3"/></a>
        </li>
        <li>
          <a href="{}">Mobile Application <FcRating className="ms-3"/></a>
        </li>
        <li>
          <a href="{}">User Journey <FcRating className="ms-3"/></a>
        </li>
        <li>
          <a href="{}">UI UX Design <FcRating className="ms-3"/></a>
        </li>
        <li>
          <a href="{}">Branding <FcRating className="ms-3"/></a>
        </li>
        <li>
          <a href="{}">Web Development <FcRating className="ms-3"/></a>
        </li>
        <li>
          <a href="{}">Marketing <FcRating className="ms-3"/></a>
        </li>
        <li>
          <a href="{}">MERN Full Stack Development <FcRating className="ms-3"/></a>
        </li>
        <li>
          <a href="{}">Animation <FcRating className="ms-3"/></a>
        </li>
        <li>
          <a href="{}">Mobile Application <FcRating className="ms-3"/></a>
        </li>
      </ul>
    </div>
  );
};

export default Bulletin;
