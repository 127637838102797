import React from "react";
import "../CSS/main.css";
import logo from "../Images/Logo/Logo.png";
import { BsChatTextFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="navigation-box">
      <div className="container px-0">
        <nav class="navbar navbar-expand-lg">
          <div class="container-fluid">
            <Link class="navbar-brand text-white" to='/'>
              <img className="nav-logo" src={logo} alt="" />
            </Link>
            <button
              class="navbar-toggler bg-white"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDropdown">
              <ul class="navbar-nav ms-auto gap-3">
                <li class="nav-item">
                  <Link to='/' class="nav-link text-white" aria-current="page">
                    Home
                  </Link>
                </li>
                <li class="nav-item">
                  <a class="nav-link text-white" href="#services">
                    Services
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link text-white" href="#portfolio">
                    Portfolio
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link text-white" href="#faq">
                    FAQ
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link text-white" href="#team">
                    Team
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link text-white" href="#blog">
                    Blog
                  </a>
                </li>
                <li class="nav-item">
                  <Link class="nav-link text-primary lets-talk-nav d-flex" to="/letsTalk">
                    Let’s talk{" "}
                    <div className="chat-logo ms-3">
                      <BsChatTextFill className="text-white" />
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;