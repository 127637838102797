import React from "react";
import { Accordion } from "react-bootstrap";
import { Link } from 'react-router-dom';

const FAQ = () => {
  return (
    <div className="faq-body" id="faq">
      <div className="container">
        <h1 className="text-center">Frequently Asked Questions</h1>

        <div className="faq-set">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>How Do I Hire You For My Project?</Accordion.Header>
              <Accordion.Body>
                Book a meeting here : <span><Link to='/letsTalk'>Book Now</Link></span> <br /> If urgent then message me here : <span> <a target= '_blank' href="https://www.facebook.com/earthycolor.agency">Facebook</a> </span> - <span> <a target= '_blank' href="https://join.skype.com/invite/YZIzn2HPMAW4"> Skype </a> </span> - <span> <a target= '_blank' href="https://wa.me/+8801740488507/"> Whatsapp </a> </span> - <span> <a target= '_blank' href="https://t.me/mrinmoymithun"> Telegram </a> </span> - <span> <a target='_blank' href="info.earthycolor@gmail.com"> Gmail </a> </span>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Who will be working on my project?</Accordion.Header>
              <Accordion.Body>
                A professional team work on your project. We have Researcher, Designer, Developers, Marketer expert.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Do I pay extra for more people?</Accordion.Header>
              <Accordion.Body>No need to pay others people.</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Do You Offer Money Back Guarantee?</Accordion.Header>
              <Accordion.Body>
                Yes, If you are not satisfied with the first draft, you have the full right to cancel the project and
                ask for a refund. So, Don't worry.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Are you work for startup?</Accordion.Header>
              <Accordion.Body>Yes, our main target startup. Who want create new something.</Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
