import React from "react";
import hc1 from "../Images/HappyCustomerPic/hc1.png";
import hc2 from "../Images/HappyCustomerPic/hc2.png";
import hc3 from "../Images/HappyCustomerPic/hc3.png";
import hc4 from "../Images/HappyCustomerPic/hc4.png";

const HappyCustomer = () => {
  return (
    <div className="container happy-customer-body">
      <h2 className="text-center">Happy Customer</h2>
      <p className="text-center">20+ happy clients all around the world. Don’t just take our word for it</p>

      <div className="row gap-4 mt-5">
        <div className="col-md customer-card p-4 bg-white">
          <p className="text-start mt-0 mb-0">
            "EarthyColor is dependable and responsive to client's request. Always easy and convenient to communicate
            with them for any issues and support.
          </p>
          <div className="happy-customer-info">
            <img src={hc1} alt="" />
            <div>
              <p className="text-start mb-0 mt-0">Annette Black</p>
              <small>Marketing Coordinator</small>
            </div>
          </div>
        </div>
        <div className="col-md customer-card p-4 bg-white">
          <p className="text-start mt-0 mb-0">
            "The client appreciates being able to speak in layman terms to EarthyColor project CEO. Their technical
            knowledge and suggestions influenced the evolution of the client's vision.
          </p>

          <div className="happy-customer-info">
            <img src={hc2} alt="" />
            <div>
              <p className="text-start mb-0 mt-0">Jenny Wilson</p>
              <small>President of Sales</small>
            </div>
          </div>
        </div>
      </div>
      <div className="row gap-4 mt-4">
        <div className="col-md customer-card p-4 bg-white">
          <p className="text-start mt-0 mb-0">
            "Thoughtfully integrating consumer feedback to improve the UX/UI, EarthyColor has developed a series of
            consistently improved web. Their pricing structure pairs value with cost savings.
          </p>

          <div className="happy-customer-info">
            <img src={hc3} alt="" />
            <div>
              <p className="text-start mb-0 mt-0">Kathryn Murphy</p>
              <small>Medical Assistant</small>
            </div>
          </div>
        </div>
        <div className="col-md customer-card p-4 bg-white">
          <p className="text-start mt-0 mb-0">
            "To the teams of EarthyColor - excellent job done with very smooth and responsive communication! The teams
            are very knowledgeable and had given us lots of valuable advices.
          </p>

          <div className="happy-customer-info">
            <img src={hc4} alt="" />
            <div>
              <p className="text-start mb-0 mt-0">Esther Howard</p>
              <small>Medical Assistant</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HappyCustomer;
