import React from "react";
import "../CSS/main.css";
import messageButton from "../Images/NewImages/rotat-button.png";
import angel from "../Images/NewImages/hero-up-arrow.png";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();

  const message = () => {
    navigate("/letsTalk");
  };

  return (
    <div className="hero-section-body">
      <section className="container">
        <div className="row">
          <div className="col-md">
            <div className="hero-description">
              <h5 className="hero-upper-title">DESIGN FOR SUCCESS</h5>
              <h1 className="hero-title">Your Business Growth Agency</h1>
              <p className="hero-paragraph mt-5 w-75">
                We design business model. We help startups to define, design, development, marketing and launch digital
                products. All in one place.
              </p>

              <div className="d-flex mt-5">
                {/* <div className="before-para-box"></div> */}
                <p className="inside-box-para">Find a thought partner!</p>
              </div>
            </div>
          </div>
          <div className="col-md right-side-hero">
            <div>
              <img className="angel-hero" src={angel} alt="" />
            </div>
            <div>
              <img onClick={message} className="message-button" src={messageButton} alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
